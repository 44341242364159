@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
  background-color: #282c34;
  font-family: 'PT Sans';
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}



.stacked-element {
  position: absolute;
}

.saving {
  z-index: 1000;
  display: none;
}

.save-button {
  display: hidden;
  position: absolute;
  bottom: 10px;
}

h1, h2, h3, h4, h5 {
  margin-bottom: 13px !important;
}