.container {
    margin: auto;
    max-width: 80%;
    padding-bottom: 60em;
}


.myImage {
    background: url('/public/cv.PNG');
    background-repeat: no-repeat;
    background-size: 200px 200px;
}

.divider {
    margin: 100px;
}

.generativeArtBanner {
    display: flex;
    flex-direction: column;
    padding: 13px;
    border-radius: 10px;
}

.generativeArtBannerText > * {
    margin: 13px 0;
    font-style: italic;
}


.canvasbanner> div > canvas {
    border-radius: 10px;
    margin: 6.5px;
}

.generativeArtBanner:hover {
    box-shadow: 3px 6px 5px 2px rgba(82,82,82,0.55);
    -webkit-box-shadow: 3px 6px 5px 2px rgba(82,82,82,0.55);
    -moz-box-shadow: 3px 6px 5px 2px rgba(82,82,82,0.55);
}

.definition {
    padding: 26px;
    font-style: italic;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: rgba(255, 255, 255, 0.795);
    background: #1A202C;
}

.illustration {
    flex: 0 0 300px;
    background-image: url('/public/Computer_banner.svg');
    background-repeat: no-repeat;
    background-size: 100%;
}

.cardList {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 13px;
    row-gap: 13px;
}

@media(min-width: 800px) {
    .definition {
        flex-direction: row;
    }

    .divider {
        margin: 300px;
    }

    .myImage {
        background-size: 400px 400px;
    }

    .illustration {
        flex: 0 0 400px;
        height: 200px;
        background-image: url('/public/Computer_banner.svg');
        background-repeat: no-repeat;
        background-size: 400px 200px;
    }      
    
    .generativeArtBanner {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
}

.deftitle {
    display: flex;
    align-items: center;
}

.sidelineTitle {
    font-size: 36px;
    font-weight: 700;
}

.sidelineNoun {
    color: #4a7d95;
    padding-left: 2rem;
}

ul.deflist {
    list-style: none;
    padding-left: 7rem;
}

.galleryTitle {
    color: #4a7d95;
    font-size: 18px;
}

.ingress {
    margin: 26px;
    font-style: italic;
}