.container {
   margin: 26px 0;
}

.sketchContainer {
    display: grid;
    grid-template: 
    'navigation'
    'sketch'
        'sidebar';
}

.sidebar {
    grid-area: sidebar;
}

.navigation {
    grid-area: navigation;
}

@media(min-width: 600px) {
    .sketchContainer {
        display: grid;
        grid-template: 'sidebar sketch'
            'sidebar sketch';
    }

    .navigation {
        display: none;
    }
}


.sketchWrapper {
    grid-area: sketch;
}