.container {
    white-space: pre-line;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    padding: 6px;
    margin-right: 10px;
    text-align: center;
}

.title {
    font-style: italic;
    font-size: 18px;
}

.navigation {
    padding: 0 13px;
}


@media(max-width: 599px) {
    .hiddenMobile {
        display: none !important;
    }

    .description {
        display: none;
    }
}


@media(min-width: 600px) {
    .container {
        width: 250px;
    }
    
    .hiddenDesktop {
        display: none !important;
    }
    .navButtons {
        margin-top: 13px;
    }

    .description {
        text-align: left;
    }
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.buttons > * {
    padding: 3px;
    text-decoration: none;
    outline: 1px solid green;

}

