.navbar {
        position: sticky;
        top: 0;
        display: flex;
        padding: 6px;
        background-color: #1A202C;
        z-index: 10;
    }

ul.links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    margin: 0;
    flex-wrap: wrap;
}

ul.links>li {
    color: white;
    padding: 0 13px;
}

@media(min-width: 600px) {
    
    ul.links {
        flex-wrap: nowrap;
    }

    .navbarContent {
        margin: 0 10%;
    }

    ul.links>li {
        color: white;
        padding: 0 13px;
    }
    
    ul.links>li>a {
        text-decoration: none;
    }
    
    ul.links>li>a:hover {
        padding-bottom: 3px;
        border-bottom: 1px solid white;
    }

}


@media(max-width: 600px) {
    .hiddenMobile {
        display: none;
    }

    .navbarContent {
        width: 100%;
    }
}

