.navbar {
    position: sticky;
    top:0;
    display: flex;
    justify-content: flex-end;
    padding: 6px;
    background-color: inherit;
    z-index: 10;
    
}

ul.links {
    display: flex;
    list-style: none;
    margin: 0;
    flex-wrap: wrap;
}

@media(min-width: 600px) {
    ul.links {
        flex-wrap: nowrap;
    }

    .navbarContent {
        margin: 0 10%;
    }
    
}


ul.links > li {
    padding: 0 13px;
    color: #eea02a;
}

ul.links > li > a {
    text-decoration: none;
    color: #eea02a;
}


ul.links > li > a:hover {
    padding-bottom: 3px;
    border-bottom: 1px solid #eea02a;
}
