.container {
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media(min-width: 600px) {
    .container {
        margin-top: 0;
    }
}